import Component from '../../../assets/scripts/modules/component'
import SwiperWrapper from '../../../assets/scripts/modules/swiper-wrapper'

const SWIPER_OPTIONS = {
  // spaceBetween: 16,
  // loopAdditionalSlides: 4
  // breakpointsInverse: true,
  loop: true,
  slidesPerView: 'auto'
}

export default class LightboxCarousel extends Component {
  init () {
    this.observer = null
    this.container = this.element.querySelector('.swiper-container')
    this.totalSlides = this.element.querySelectorAll('.swiper-container .swiper-slide').length
    this.currentSlideIndex = 1

    this.container.querySelector('.custom-pagination .current').innerHTML = this.currentSlideIndex
    this.container.querySelector('.custom-pagination .total').innerHTML = this.totalSlides

    if (this.container) {
      this.liveregion = document.createElement('div')
      this.liveregion.setAttribute('aria-live', 'polite')
      this.liveregion.setAttribute('aria-atomic', 'true')
      this.liveregion.setAttribute('class', 'liveregion visuallyhidden')
      this.container.appendChild(this.liveregion)
      this.liveregion.style.position = 'absolute'
    }

    let swiperOptions = { ...SWIPER_OPTIONS }

    if ([...this.container.querySelectorAll('.swiper-slide')].length < 2) {
      swiperOptions = {
        ...swiperOptions,
        ...{ noSwipe: true, navigation: true, pagination: true, loop: true }
      }
      this.container.querySelectorAll('.swiper-button-prev, .swiper-button-next').forEach(button => button.parentNode.removeChild(button))
    }

    // - disable slider with attribute on desktop
    // - disable slider on desktop if items are less than 4
    // on mobile the slider must keep working
    if ((this.container.getAttribute('data-disable-slider') === 'true' || this.totalSlides < 5) && window.innerWidth > 799) {
      // hide swiper navigation
      if (this.container.querySelector('.swiper-navigation')) {
        this.container.querySelector('.swiper-navigation').style.display = 'none'
      }
    } else {
      // activate swiper
      this.swiperWrapper = new SwiperWrapper(this.container, swiperOptions, [], {
        slideChange: () => this.onSwiperSlideChange()
      })
    }

    this.setupBtns()
  }

  setupBtns () {
    const container = this.element

    container.addEventListener('click', function (e) {
      const btn = e.target.closest('.lightbox-btn')
      if (btn) {
        const lightboxId = btn.getAttribute('data-lightbox-id')
        window.dispatchEvent(new CustomEvent('open-lightbox', { detail: lightboxId }))
      }
    })
  }

  onSwiperSlideChange () {
    if (!this.swiperWrapper || !this.swiperWrapper.swiper || !this.swiperWrapper.swiper.slides) {
      return
    }

    const current = this.swiperWrapper.swiper.slides[this.swiperWrapper.swiper.activeIndex]
    const all = Object.values(this.swiperWrapper.swiper.slides).filter(slide => !!slide.tagName)
    const currentWithDuplicates = all.filter(slide => slide.getAttribute('data-swiper-slide-index') === current.getAttribute('data-swiper-slide-index'))

    if (this.liveregion) {
      this.liveregion.textContent = `Item ${this.swiperWrapper.swiper.activeIndex + 1} of ${this.swiperWrapper.swiper.slides.length}`
    }

    currentWithDuplicates.forEach(slide => {
      slide.classList.add('swiper-slide--activated')
    })

    // Warkaround for swiper bug; see https://github.com/nolimits4web/Swiper/issues/2099
    if (SWIPER_OPTIONS.loop) {
      setTimeout(() => {
        [...this.element.querySelectorAll('.swiper-button-disabled')].forEach(button => {
          button.classList.remove('swiper-button-disabled')
        })
      }, 500)
    }

    this.currentSlideIndex = this.swiperWrapper.swiper.realIndex + 1
    if (this.container.querySelector('.custom-pagination .current')) {
      this.container.querySelector('.custom-pagination .current').innerHTML = this.currentSlideIndex
    }
  }
}

window.addEventListener('init-load', () =>
  document.querySelectorAll('.lightbox-carousel').forEach(element => {
    element.instance = element.instance || new LightboxCarousel(element)
  })
)
